/**
 * Represents the RequestValidationPage component.
 * This component displays a page for requesting email validation.
 */
import React from 'react';
import { Typography, Container, Button } from '@mui/material';
import LoginHeader from '../LoginHeader';
import { useContext } from 'react';
import AuthContext from '../Context/AuthContext';

/**
 * Represents the RequestValidationPage component.
 * This component displays a page for requesting email validation.
 * @returns JSX.Element
 */
const RequestValidationPage = (): JSX.Element => {
    const { username, setIsAuthenticated } = useContext(AuthContext);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    }

    return (
        <>
            <LoginHeader />
            <Container maxWidth="sm">
                    <Typography variant="h4">Welcome {username}</Typography>
                        <Typography variant="h4">Check Your Email</Typography>
                        <Typography variant="body1">
                            Please check your email for a validation link. if you already validate refresh the page.
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button>
                    </Container>

           
        </>
    );
};

export default RequestValidationPage;
