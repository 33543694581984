import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home, Explore, Settings } from '@mui/icons-material';

const classes = {
    root: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
    },
};

interface FooterProps {
    setLocation: (location: string) => void;
}

/**
 * Footer component for navigation.
 * 
 * @returns The rendered Footer component.
 */

const Footer: React.FC<FooterProps> = (props:FooterProps) => {
    const { setLocation } = props;
    

    return (
        <BottomNavigation sx={classes.root} showLabels>
            <BottomNavigationAction label="Home" icon={<Home />} onClick={() => setLocation('home')}/>
            <BottomNavigationAction label="Explore" icon={<Explore />} />
            <BottomNavigationAction label="Settings" icon={<Settings />} onClick={() => setLocation('Dogs')} />
        </BottomNavigation>
    );
};

export default Footer;