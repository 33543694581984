
import React, { useState } from 'react';
import { TextField, Button, Typography, Box, FormControl } from '@mui/material';
import LoginHeader from './LoginHeader';
import { isEmail } from 'validator';
import zxcvbn from 'zxcvbn';
import { login,register } from './Utils/apiUtils';
import AuthContext from './Context/AuthContext';

const useStyles = () => ({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiFormLabel-root': {
            color: 'white',
        },
    },
    box: {
        backgroundColor: '#161616',
        height: '100%',
    },
    button: {
        width: '31%',
    },
    bottomBox: {
        padding: 3,
        position: 'fixed',
        bottom: '6%',
        left: '19%',
        width: '50%',
    },
    subtitle: {
        display: 'contents',
        width: '50%',
    },
    RegisterButton: {
        width: '50%',
    },
    FormControl: {
        width: '100%',
        padding: 0,
        textAlign: 'center',
        alignItems: 'center'
    },
});

/**
 * Represents a login form component.
 * @component
 */
const LoginForm = () => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginForm, setLoginForm] = useState<boolean>(true);
    const [usernameForm, setUsernameForm] = useState<string>('');
    const [passwordStrength, setPasswordStrength] = useState<number>(0);
    const [registerError, setRegisterError] = useState<string>('');
    const [loginError, setLoginError] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const { setUsername, setIsValidated , setIsAuthenticated} = React.useContext(AuthContext);

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsernameForm(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        const passwordStrength = zxcvbn(newPassword).score;
        setPasswordStrength(passwordStrength);
    };

    const handleSubmitLogin = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (!isEmail(email) || password === '' ) {
            setRegisterError('Please enter valid email and password.');
            return;
        }else{
            setRegisterError('');
            login(email,password).then((response) => {
                if (response.data) {
                    setUsername(response.data.username);
                    setIsAuthenticated(true);
                    setIsValidated(response.data.isValidated);
                    localStorage.setItem('token', response.data.accessToken);
                }
            }).catch((error) => {
                console.log(error);
                setLoginError('Invalid email or password');
            });
        }
    };

    const handleSubmitRegister = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        if (!isEmail(email) || password === '' || usernameForm === '' || passwordStrength < 2) {
            setRegisterError('Please enter valid email, username, and password.');
            return;
        } else {
            setRegisterError('');
            register(usernameForm,email,password).then((response) => {
                if (response.data) {
                    setIsAuthenticated(true);
                    setUsername(response.data.username);
                    setIsValidated(response.data.validated);
                    localStorage.setItem('token', response.data.token);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        

    };

    return (
        <>
            <Box sx={classes.box}>
                <LoginHeader />
                <Box sx={{ p: 3 }}>
                    {loginForm ?
                        
                        <FormControl  sx={classes.FormControl}>
                            <TextField
                                label="Email or Username"
                                // type="text"
                                value={email}
                                onChange={handleEmailChange}
                                fullWidth
                                margin="normal"
                                sx={classes.root}
                                required
                                error={!isEmail(email)}
                                helperText={!isEmail(email) && email ? 'please enter valid email' : ''}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                fullWidth
                                sx={classes.root}
                                margin="normal"
                                required
                            />
                            <Button type="submit" variant="contained" color="secondary" sx={classes.button} onClick={handleSubmitLogin}>
                                Sign In
                            </Button>
                            <Typography variant="subtitle1" color="error">
                                {loginError}
                            </Typography>
                            </FormControl>
                        
                        :
                        <FormControl sx={classes.FormControl}>
                            <TextField
                                label="Username"
                                type="string"
                                value={usernameForm}
                                sx={classes.root}
                                onChange={handleUsernameChange}
                                fullWidth
                                margin="normal"
                                required
                            />
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                sx={classes.root}
                                onChange={handleEmailChange}
                                fullWidth
                                margin="normal"
                                required
                                error={!isEmail(email)}
                                helperText={!isEmail(email) && email ? 'please enter valid email' : ''}
                            />


                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                sx={classes.root}
                                onChange={handlePasswordChange}
                                fullWidth
                                margin="normal"
                                required
                                error={password.length < 8 || passwordStrength < 3}
                                helperText={
                                    (password && password.length < 8 && 'Password must be at least 8 characters long') ||
                                    (password && passwordStrength < 2 && 'Password is too weak')
                                }
                            />
                            <TextField
                                label="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                error={confirmPassword !== password && confirmPassword !== ''}
                                helperText={confirmPassword !== password && confirmPassword !== '' ? 'Passwords do not match' : ''}
                                fullWidth
                                sx={classes.root}
                                margin="normal"
                                required
                            />
                            <Button type='submit' variant="contained" color="secondary" sx={classes.button} onClick={handleSubmitRegister}>
                                Register
                            </Button>
                            <Typography variant="subtitle1" color="error">
                                {registerError}
                            </Typography>
                            </FormControl>
                        
                    }
                    <Box sx={classes.bottomBox}>
                        <Typography variant='subtitle1' sx={classes.subtitle}>
                            {loginForm ? 'Not Register yet?' : 'Already have an account?'}
                        </Typography>
                        <Button variant="outlined" color="primary" sx={classes.RegisterButton} onClick={() => setLoginForm(!loginForm)}>
                            {loginForm ? 'Register' : 'Sign In'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default LoginForm;
