/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

/**
 * Represents the authentication context.
 */
interface IAuthContext {
    isAuthenticated: boolean;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
    username: string;
    setUsername: React.Dispatch<React.SetStateAction<string>>;
    isValidated: boolean;
    setIsValidated: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * The authentication context.
 */
const AuthContext = React.createContext<IAuthContext>({
    isAuthenticated: false,
    setIsAuthenticated: function (value: React.SetStateAction<boolean>): void {
        throw new Error('Function not implemented.');
    },
    username: '',
    setUsername: function (value: React.SetStateAction<string>): void {
        throw new Error('Function not implemented.');
    },
    isValidated: false,
    setIsValidated: function (value: React.SetStateAction<boolean>): void {
        throw new Error('Function not implemented.');
    },
});

export default AuthContext;
