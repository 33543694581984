import React, { useState } from 'react';
import { List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

interface Dog {
    name: string;
    breed: string;
    age: number;
}

const DogList = () => {
    const [open, setOpen] = useState(false);
    const [selectedDog, setSelectedDog] = useState<Dog>();

    const dogs = [
        { name: 'Rex', breed: 'German Shepherd', age: 3 },
        { name: 'Bella', breed: 'Labrador Retriever', age: 5 },
        // Add more dogs here...
    ];

    const handleClickOpen = (dog:Dog) => {
        setSelectedDog(dog);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <List>
                {dogs.map((dog, index) => (
                    <ListItem button onClick={() => handleClickOpen(dog)} key={index}>
                        <ListItemText primary={dog.name} />
                    </ListItem>
                ))}
            </List>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{selectedDog?.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Breed: {selectedDog?.breed}</DialogContentText>
                    <DialogContentText>Age: {selectedDog?.age}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DogList;