/**
 * Provides authentication context to its child components.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered component.
 */
import React, { useState, ReactNode } from 'react';
import AuthContext from './AuthContext';

interface IAuthProvider {
    children: ReactNode;
}

function AuthProvider({children}: IAuthProvider) {
  const [username, setUsername] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AuthContext.Provider value={{ username, setUsername, isValidated, setIsValidated, isAuthenticated,setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;