import React, { ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import {API_URL} from '../../env/environnement';
import { useContext } from 'react';
import  AuthContext  from '../Context/AuthContext';

/**
 * Higher-order component that wraps the children components and handles authentication.
 * It checks the authentication status by making an API call and redirects to the login page if necessary.
 * @param children The child components to be wrapped.
 * @returns The wrapped components.
 */
const AuthWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { setIsAuthenticated, setIsValidated} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    axios.interceptors.response.use(
        response => {
          // If the request succeeds, we don't have to do anything and just return the response
          return response;
        },
        error => {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // We can check for a 403 status and redirect to the login page
          if (axios.isAxiosError(error) && (error.response?.status === 403 || error.response?.status === 401)) {
            console.log('Redirecting to login page')
            setIsAuthenticated(false);
          }
      
          return Promise.reject(error);
        }
      );

    useEffect(() => {
        // Simulating an API call to check authentication status
        const checkAuthentication = async () => {
            try {
                const header = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
                const auth = await axios.get(`${API_URL}/auth/check`, header);
                setIsAuthenticated(true);
                setIsValidated(auth.data.isValidated);

            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 403) {
                    setIsAuthenticated(false);
                } else {
                    console.error('Error checking authentication:', error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        checkAuthentication();
    }, []);

    if (isLoading) {
        // Render loading spinner or skeleton screen while checking authentication
        return <div>Loading...</div>;
    }


    return <>{children}</>;
};

export default AuthWrapper;