
import React, { useState } from 'react';
import { Typography, TextField, Button, Container, Grid } from '@mui/material';
import { resendEmail } from '../Utils/apiUtils';
import LoginHeader from '../LoginHeader';
import { isEmail } from 'validator';

const classes = {
    typoContainer: {
        paddingTop: '10%',
    },
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiFormLabel-root': {
            color: 'white',
        }
    },
    button: {
        '& .Mui-disabled': {
            opacity: 0.5,
            
        },
    },
}

interface ResendEmailProps{
    tokenExpired?:boolean
}
/**
 * Represents the Resend Email Page component.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.tokenExpired] - Indicates if the token has expired.
 * @returns {JSX.Element} The Resend Email Page component.
 */
const ResendEmailPage = (props:ResendEmailProps) => {
    const { tokenExpired } = props;
    const [email, setEmail] = useState<string>('');
    const [emailNotRegister, setEmailNotRegister] = useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = useState<string>('');


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleResendEmail = () => {
        // Logic to resend validation email
        if (!isEmail(email)) {
            return;
        }
        resendEmail(email).then((res) => {
            if (res.status === 200) {
                setEmailNotRegister(false);
                setConfirmationMessage(res.data.message);
            }
        }
        ).catch(() => {
            setEmailNotRegister(true);
        });

    };

    return (
        <>
            <LoginHeader />
            <Container maxWidth="sm" sx={classes.typoContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">There has been an issue</Typography>
                        <Typography variant="body1">Resend Validation Email</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={'Email'} required fullWidth type="email" value={email} onChange={handleEmailChange} sx={classes.root} error={!isEmail(email) && email !== ''} helperText={!isEmail(email) && email ? 'please enter valid email' : ''} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={!isEmail(email)} variant="contained" onClick={handleResendEmail} color='secondary'>Resend Email</Button>
                    </Grid>
                    {confirmationMessage!=='' && <Grid item xs={12}>
                        <Typography variant="body1">{confirmationMessage}</Typography>
                    </Grid>}
                    {emailNotRegister && <Grid item xs={12}>
                        <Typography color='error' variant="body1">Email not registered, please sign up</Typography>
                    </Grid>}
                    {tokenExpired && <Grid item xs={12}>
                        <Typography color='error' variant="body1">Validation link has expired</Typography>
                    </Grid>}
                </Grid>
            </Container>
        </>
    );
};

export default ResendEmailPage;
