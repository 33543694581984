import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme,Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';


const useStyles = (theme: Theme) => {
    return {
        card: {
            padding: '0px',
            height: '35%',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
        },
        cardContent: {
            height: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
        typography: {
            fontColor: 'white',
        },
        avatar: {
            width: '50%',
            height: 'auto',
            marginLeft: '25%',
            backgroundColor: theme.palette.secondary.main,
        },
        title: {
            color: theme.palette.primary.main,
        },
        subtitle: {
            color: theme.palette.primary.main,
        }
    };
}


/**
 * Represents the login header component.
 * This component displays the header section of the login page.
 */
const LoginHeader: React.FC = () => {
    const theme:Theme = useTheme<Theme>();
    const classes = useStyles(theme);

    return (
        <Card sx={classes.card}>
            <CardContent sx={classes.cardContent}>
                <Grid container spacing={2} justifyContent="center" alignItems="middle">
                    <Grid item xs={12}>
                        <Avatar alt="Dogctivity" src="/logoLoginDogctivity2.png" sx={classes.avatar} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" sx={classes.title}>
                            Dogctivity
                        </Typography>
                        <Typography sx={classes.subtitle}>
                            Join today to track your dog&aposs activity
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default LoginHeader;