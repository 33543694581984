
import ReactDOM from 'react-dom/client';
import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthProvider from './Components/Context/AuthProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#dedede', // white
    },
    secondary: {
      main: '#d62622', // red
    },
    error: {
      main: '#d62622',
    },
    action: {
        disabled: '##ffffff',
        disabledBackground: '#e08583',
        disabledOpacity: 0.5,
      }
    },
    typography: {
      h4: {
        color: '#dedede',
      },
      subtitle1: {
        color: '#dedede',
      },
      body1: {
        color: '#dedede',
      },
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  <ThemeProvider theme={theme} >
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider >
      <App />
      </AuthProvider>
    </LocalizationProvider>
  </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
