import React, { useEffect } from 'react';
import './App.css';
import Footer from './Components/FooterNav';
import AuthContext from './Components/Context/AuthContext';
import CustomTabPanel from './Components/CustomTabPanel';
import LoginForm from './Components/LoginForm';
import AuthWrapper from './Components/Wrapper/AuthWrapper';
import ValidatedEmailPage from './Components/Page/ValidationPage';
import ResendEmailPage from './Components/Page/ResendEmailPage';
import RequestValidationPage from './Components/Page/RequestValidationPage';
import DogList from './Components/Page/DogView';


function App() {
  const [location, setLocation] = React.useState('Home');
  const { isAuthenticated, isValidated } = React.useContext(AuthContext);
  const [validationLinkExpired, setValidationLinkExpired] = React.useState<boolean>(false);


  useEffect(() => {
    console.log('isAuthenticated', isAuthenticated)
    if (window.location.pathname.includes('mailvalidated')) {
      setLocation('/mailvalidated')
    } else if (window.location.pathname.includes('resendemail')) {
      setLocation('/resendemail')
    }
    else if (window.location.pathname.includes('resendemailexpired')) {
      setValidationLinkExpired(true);
      setLocation('/resendemail')
    }
    else if (!isAuthenticated) {
      setLocation('/login')
    } 
    else if (!isValidated) {
      setLocation('/requestvalidation')
    }
    else {
      setLocation('Home')
    }
  }, [window.location.pathname, isAuthenticated, isValidated]);

  useEffect(() => {
console.log('location', location)
  }, [location]);

  return (
    <div className="App">
      
        <AuthWrapper>
          <CustomTabPanel value={location} index={'/'}>
          </CustomTabPanel>
          <CustomTabPanel value={location} index={'/mailvalidated'}>
            <ValidatedEmailPage />
          </CustomTabPanel>
          <CustomTabPanel value={location} index={'/resendemail'}>
            <ResendEmailPage tokenExpired={!validationLinkExpired} />
          </CustomTabPanel>
          <CustomTabPanel value={location} index={'/requestvalidation'}>
            <RequestValidationPage />
          </CustomTabPanel>
          <CustomTabPanel value={location} index={'/login'}>
            <LoginForm></LoginForm>
          </CustomTabPanel>
          <CustomTabPanel value={location} index={'Dogs'}>
            <DogList></DogList>
          </CustomTabPanel>

          <Footer setLocation={setLocation} />
        </AuthWrapper>
    </div>
  );
}

export default App;
