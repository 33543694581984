/**
 * Represents a page component for displaying a message indicating that the email has been successfully validated.
 */
import React from 'react';
import { Typography, Container } from '@mui/material';
import LoginHeader from '../LoginHeader';

const classes = {
    typoContainer: {
        paddingTop: '10%',
    },
};

const ValidatedEmailPage: React.FC = () => {
    return (
        <>
            <LoginHeader />
            <Container maxWidth="sm" sx={classes.typoContainer}>
                <Typography variant="h4" align="center" gutterBottom>
                    Email Validated
                </Typography>
                <Typography variant="body1" align="center">
                    Your email has been successfully validated and you can now proceed with using the app.
                </Typography>
            </Container>
        </>
    );
};

export default ValidatedEmailPage;