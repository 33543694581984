import { Box } from '@mui/material';
import * as React from 'react';

/**
 * CSS classes for the CustomTabPanel component.
 */
const classes = {
  tabPanel: {
    height: '100%',
  },
};

/**
 * Props for the TabPanel component.
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

/**
 * Renders a custom tab panel.
 * @param props - The component props.
 * @returns The rendered custom tab panel.
 */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={classes.tabPanel}
    >
      {value === index && (
        <Box style={classes.tabPanel}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

export default CustomTabPanel;
