import axios from 'axios';
import { API_URL } from '../../env/environnement';


const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

/**
 * Registers a user with the provided username, email, and password.
 * @param {string} username - The username of the user.
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<any>} - A promise that resolves to the response from the server.
 * @throws {Error} - If an error occurs during the registration process.
 */
const register = async (username: string, email: string, password: string) => {
    try {
        const response = await api.post('/auth/register', {
            username,
            email,
            password,
        });
        return response;
    } catch (error) {
        throw new Error(String(error));
    }
};

/**
 * Logs in a user with the provided email and password.
 * @param email - The user's email.
 * @param password - The user's password.
 * @returns A Promise that resolves to the response from the login API endpoint.
 * @throws If an error occurs during the login process.
 */
const login = async (email: string, password: string) => {
    try {
        const response = await api.post('/auth/login', {
            email,
            password,
        });
        return response;
    } catch (error) {
        throw new Error(String(error));
    }
};

/**
 * Sends a request to resend a validation email.
 * 
 * @param email - The email address to send the validation email to.
 * @returns A Promise that resolves to the response from the server.
 * @throws An error if there is an issue with the request or response.
 */
const resendEmail = async (email: string) => {
    try {
        const response = await api.post('/auth/requestValidationMail', {
            email,
        });
        return response;
    } catch (error) {
        throw new Error(String(error));
    }
}



export { register, login, resendEmail };